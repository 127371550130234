import { Controller } from '@hotwired/stimulus'
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = ['open', 'close', 'overlay', 'menu', 'offcanvas']

  toggleSidebar () {
    if (this.menuTarget.classList.contains('hidden')) {
      enter(this.closeTarget)
      enter(this.menuTarget)
      enter(this.overlayTarget)
      enter(this.offcanvasTarget)
    } else {
      leave(this.closeTarget)
      leave(this.overlayTarget)
      leave(this.offcanvasTarget).then(() => {
        leave(this.menuTarget)
      })
    }
  }
}
