// app/javascript/controllers/dropdown_controller.js
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["dropdown", "chevron"]

  toggle() {
    this.dropdownTarget.classList.toggle("hidden");
    this.chevronTarget.classList.toggle("rotate-180");
  }
}