import { Controller } from "@hotwired/stimulus"
import Dropzone from "dropzone"
import 'dropzone/dist/dropzone.css'
import 'dropzone/dist/basic.css'
import { DirectUpload } from "@rails/activestorage"

export default class extends Controller {
  static targets = ["input"]

  connect() {
    console.log("Dropzone connected")
    Dropzone.autoDiscover = false
    this.inputTarget.disabled = true
    this.inputTarget.style.display = "none"

    const dropzone = new Dropzone(this.element, {
      url: '/',
      maxFiles: 10,
      maxFilesize: 10,
      autoQueue: false
    })

    dropzone.on("addedfile", file => {
      this.addMetadataFields(file)  // Add metadata fields below each file preview
      setTimeout(() => {
        if (file.accepted) {
          const upload = new DirectUpload(file, this.url)
          upload.create((error, blob) => {
            if (error) {
              console.error("Direct upload error:", error)
              return
            }
            this.hiddenInput = document.createElement("input")
            this.hiddenInput.type = "hidden"
            this.hiddenInput.name = this.inputTarget.name
            this.hiddenInput.value = blob.signed_id
            this.inputTarget.parentNode.insertBefore(this.hiddenInput, this.inputTarget.nextSibling)
            dropzone.emit("success", file)
            dropzone.emit("complete", file)
          })
        }
      }, 500)
    })
  }

  // Dynamically add metadata fields below each image preview
  addMetadataFields(file) {
    const metadataContainer = document.createElement("div")
    metadataContainer.classList.add("image-metadata-fields", "mt-2")

    // Thumbnail checkbox
    const thumbnailLabel = document.createElement("label")
    thumbnailLabel.textContent = "Thumbnail"
    const thumbnailInput = document.createElement("input")
    thumbnailInput.type = "checkbox"
    thumbnailInput.name = "listing[image_metadata_attributes][][thumbnail]"
    thumbnailInput.value = true
    thumbnailLabel.appendChild(thumbnailInput)
    metadataContainer.appendChild(thumbnailLabel)

    // Position number field
    const positionLabel = document.createElement("label")
    positionLabel.textContent = "Position:"
    const positionInput = document.createElement("input")
    positionInput.type = "number"
    positionInput.name = "listing[image_metadata_attributes][][position]"
    positionInput.classList.add("position-field")
    positionLabel.appendChild(positionInput)
    metadataContainer.appendChild(positionLabel)

    // Attach metadata fields below the file preview
    file.previewElement.appendChild(metadataContainer)
  }

  get url() {
    return this.inputTarget.getAttribute('data-direct-upload-url')
  }
}
