import { Controller } from "@hotwired/stimulus"
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = ['menu', 'open', 'close', 'burger', 'cross']

  toggleMenu () {
    if (this.hasBurgerTarget && this.hasCrossTarget) {
      this.burgerTarget.classList.toggle('hidden')
      this.crossTarget.classList.toggle('hidden')
    }
    if (this.menuTarget.classList.contains('hidden')) {
      enter(this.menuTarget)
    } else {
      leave(this.menuTarget)
    }
  }

  // Close if clicking away from menu or button
  hideMenu (event) {
    const openClicked = this.openTarget.contains(event.target)
    const menuClicked = this.menuTarget.contains(event.target)

    if (!openClicked && !menuClicked) {
      if (this.hasBurgerTarget && this.hasCrossTarget) {
        this.burgerTarget.classList.remove('hidden')
        this.crossTarget.classList.add('hidden')
      }
      leave(this.menuTarget)
    }
  }
}
